// ActionCreator
import { Action } from 'redux'

enum ActionNames {
  FETCH_AUTH_LOTTEY_START = 'sns_auth/fetch_auth_lottery_start',
  FETCH_AUTH_LOTTEY_FINISH = 'sns_auth/fetch_auth_lottery_finish'
}

interface FetchRequestAuthLotteryStartAction extends Action {
  type: ActionNames.FETCH_AUTH_LOTTEY_START
  appAction: boolean
}

export const fetchRequestAuthLotteryStart = (): FetchRequestAuthLotteryStartAction => ({
  type: ActionNames.FETCH_AUTH_LOTTEY_START,
  appAction: true
})

interface FetchRequestAuthLotteryFinishAction extends Action {
  type: ActionNames.FETCH_AUTH_LOTTEY_FINISH
  appAction: boolean
}
export const fetchRequestAuthLotteryFinish = (): FetchRequestAuthLotteryFinishAction => ({
  type: ActionNames.FETCH_AUTH_LOTTEY_FINISH,
  appAction: true
})

//reducer

export type SnsAuthActions =
  | FetchRequestAuthLotteryStartAction
  | FetchRequestAuthLotteryFinishAction

export interface SnsAuthState {
  loadingAuthLotteryCount: number
}

const initialState: SnsAuthState = {
  loadingAuthLotteryCount: 1
}

export default function reducer(
  state: SnsAuthState = initialState,
  action: SnsAuthActions
): SnsAuthState {
  switch (action.type) {
    case ActionNames.FETCH_AUTH_LOTTEY_START: {
      return Object.assign({}, state, {
        loadingAuthLotteryCount: 1,
        loadingCount: 1
      })
    }
    case ActionNames.FETCH_AUTH_LOTTEY_FINISH: {
      return Object.assign({}, state, {
        loadingAuthLotteryCount: 0,
        loadingCount: 0
      })
    }

    default:
      return state
  }
}
