import {
  fetchRequestFinish,
  fetchRequestStart,
  fetchRequestGetCampaignFinish,
  failCampaignCodeInput
} from '../../src/modules/toppage'
import {
  fetchRequestAuthLotteryStart,
  fetchRequestAuthLotteryFinish
} from '../../src/modules/snsAuth'
import {
  SetLotteryResult,
  SetCampaignCodeState,
  SetCampaignKeyState
} from '../../src/modules/lottery'
import { push, replace } from 'connected-react-router'
import { ApiCallFailError } from '../../src/error'
import { bugsnagClient } from '../../src/bugsnag'
import { SetCampaignInfo } from '../../src/modules/campaign'
import { ReduxAction } from '../../src/store'

interface Window {
  grecaptcha: {
    getResponse(): string
    reset(): void
  }
  location: {
    hash: string
    pathname: string
  }
}
declare const window: Window

interface LineParams {
  campaignKey: string | undefined
  idToken: string | null | undefined
  accessToken: string | null | undefined
}

export default class ActionDispatcher {
  dispatch: (action: ReduxAction) => ReduxAction

  constructor(dispatch: (action: ReduxAction) => ReduxAction) {
    this.dispatch = dispatch
  }

  public push(urlPath: string, urlState?: string | {}): void {
    this.dispatch(push({ pathname: urlPath, state: urlState }))
  }

  public resetLocationState(urlPath: string): void {
    this.dispatch(replace(urlPath, undefined))
  }

  public setLotteryResult(lotteryResult: string): void {
    this.dispatch(SetLotteryResult(lotteryResult))
  }

  public setCampaignCode(campaignCode: string): void {
    this.dispatch(SetCampaignCodeState(campaignCode))
  }

  public setCampaignKey(CampaignKey: string): void {
    this.dispatch(SetCampaignKeyState(CampaignKey))
  }

  public setCampaignInfo(campaignInfo?: { [key: string]: string }): void {
    this.dispatch(SetCampaignInfo(campaignInfo))
  }

  public failCampaignCodeInput(): void {
    this.dispatch(failCampaignCodeInput())
  }

  public async asyncGetCampaign(
    campaignKey: string | undefined
  ): Promise<void> {
    try {
      const myHeaders = new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
      const response: Response = await fetch(`/sbg-webcp-info/${campaignKey}`, {
        headers: myHeaders
      })
      if (response.status === 200) {
        // 取得成功の場合
        const json = await response.json()
        this.setCampaignInfo(json)
      } else {
        // 取得失敗の場合
        const json: {
          status: number
          code: string
          message: string
        } = await response.json()
        throw new ApiCallFailError(response.status, json.code)
      }
    } catch (e) {
      bugsnagClient.notify(e)
      this.setCampaignInfo(undefined)
      if (e.status !== 404) {
        // それ以外のエラーの場合
        this.push('/app_error')
      }
    } finally {
      this.dispatch(fetchRequestGetCampaignFinish())
    }
  }

  public async getLotteryHistory(
    campaignKey: string,
    idToken: string
  ): Promise<
    {
      status: string
      date: string
      giftUrl: string
      giftName: string
    }[]
  > {
    try {
      const requestHash: {
        idToken: string
        webCampaignKey: string
      } = {
        idToken: idToken,
        webCampaignKey: campaignKey
      }

      const body: string = JSON.stringify(requestHash)
      const myHeaders = new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
      const response: Response = await fetch(
        `/sbg-webcp-daily-lottery/lottery-history`,
        {
          method: 'POST',
          headers: myHeaders,
          body: body
        }
      )

      if (response.status === 200) {
        // 抽選成功の場合
        const json = await response.json()
        return json.histories
      } else {
        // 取得失敗の場合
        const json: {
          status: number
          code: string
          message: string
        } = await response.json()
        throw new ApiCallFailError(response.status, json.code)
      }
    } catch (error) {
      console.log('error', error)
      bugsnagClient.notify(error)
    }
    return []
  }

  public async getWebCasSurveyStatus(
    campaignKey: string,
    campaignCode: string
  ): Promise<boolean | undefined> {
    try {
      const requestHash: {
        userId: string
        webCampaignKey: string
      } = {
        userId: campaignCode,
        webCampaignKey: campaignKey
      }

      const body: string = JSON.stringify(requestHash)
      const myHeaders = new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
      const response: Response = await fetch(
        `/sbg-webcp-survey-webcas/status`,
        {
          method: 'POST',
          headers: myHeaders,
          body: body
        }
      )
      let webcasSurveyStatus = false
      if (response.status === 200) {
        // 抽選成功の場合
        const json = await response.json()
        webcasSurveyStatus = json.statusSubmitted
      }
      return webcasSurveyStatus
    } catch (error) {
      this.push('/app_error')
      bugsnagClient.notify(error)
    }
  }

  public async updateWebCasSurveyStatus(
    campaignKey: string,
    campaignCode: string
  ): Promise<boolean | undefined> {
    try {
      const requestHash: {
        userId: string
        webCampaignKey: string
      } = {
        userId: campaignCode,
        webCampaignKey: campaignKey
      }

      const body: string = JSON.stringify(requestHash)
      const myHeaders = new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
      const response: Response = await fetch(
        `/sbg-webcp-survey-webcas/submit`,
        {
          method: 'POST',
          headers: myHeaders,
          body: body
        }
      )
      let webcasUpdated = false
      if (response.status === 200) {
        // 抽選成功の場合
        const json = await response.json()
        webcasUpdated = json.webcasUpdated
      }
      return webcasUpdated
    } catch (error) {
      this.push('/app_error')
      bugsnagClient.notify(error)
    }
  }

  public async asyncLottery(
    campaignCode: string | undefined,
    campaignKey: string | undefined
  ): Promise<void> {
    const token: string = window.grecaptcha.getResponse()
    if (
      token.length === 0 ||
      campaignCode === undefined ||
      campaignKey === undefined
    ) {
      return
    }
    if (campaignCode === '') {
      window.grecaptcha.reset()
      this.dispatch(failCampaignCodeInput())
      return
    }
    this.dispatch(fetchRequestStart())
    try {
      const requestHash: {
        campaignCode: string
        webCampaignKey: string
        token: string
      } = {
        campaignCode: campaignCode,
        webCampaignKey: campaignKey,
        token: token
      }
      const body: string = JSON.stringify(requestHash)
      const myHeaders = new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${window.location.hash.slice(1)}`,
        Accept: 'application/json'
      })
      const response: Response = await fetch(`/sbg-webcp-code-lottery`, {
        method: 'POST',
        headers: myHeaders,
        body: body
      })
      if (response.status === 201 || response.status === 200) {
        // 抽選成功の場合
        const json = await response.json()
        this.setCampaignCode(campaignCode)
        this.setCampaignKey(campaignKey)
        //locaiton.stateに抽選結果の値を渡す
        this.push(`/${campaignKey}/${campaignCode}/lottery`, json)
      } else {
        // 抽選失敗の場合
        const json: {
          status: number
          code: string
          message: string
        } = await response.json()
        throw new ApiCallFailError(response.status, json.code)
      }
    } catch (e) {
      bugsnagClient.notify(e)
      if (e.status === 401) {
        // ReCaptureでエラー
        if (e.code === '40101') {
          this.push('/invalid_token')
        }
        if (e.code === '40106') {
          this.push('/rate_limit')
        }
      } else if (e.status === 404) {
        if (e.code === '40401' || e.code === '40403') {
          window.grecaptcha.reset()
          this.dispatch(failCampaignCodeInput())
          this.push(`/${campaignKey}`)
          return
        } // 有効なcampaignCodeではない場合
        if (e.code === '40402') {
          this.push('/campaign_period')
        } // キャンペーン期間外の場合
      } else {
        // それ以外のエラーの場合
        this.push('/app_error')
      }
    } finally {
      this.dispatch(fetchRequestFinish())
    }
  }
  public async asyncLineAuthLottery(params: LineParams): Promise<void> {
    if (params.campaignKey === undefined) {
      return
    }
    this.dispatch(fetchRequestStart())
    let pushPath = ''
    let pushJson = undefined
    try {
      const myHeaders = new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${params.idToken}`,
        Accept: 'application/json'
      })
      const requestHash = {
        webCampaignKey: params.campaignKey,
        accessToken: params.accessToken
      }
      const body: string = JSON.stringify(requestHash)
      const response: Response = await fetch(
        `/sbg-webcp-liffapp-lottery/${params.campaignKey}`,
        {
          method: 'POST',
          credentials: 'include',
          headers: myHeaders,
          body: body
        }
      )
      if (response.status === 201 || response.status === 200) {
        // 抽選成功の場合
        pushJson = await response.json()
        //locaiton.stateに抽選結果の値を渡す
        pushPath = `/${params.campaignKey}/line_auth/lottery`
      } else {
        // 抽選失敗の場合
        const json: {
          status: number
          code: string
          message: string
        } = await response.json()
        throw new ApiCallFailError(response.status, json.code)
      }
    } catch (e) {
      bugsnagClient.notify(e)
      if (e instanceof ApiCallFailError) {
        if (e.status === 400) {
          // トークンの認証でエラー
          pushPath = '/invalid_session'
        } else if (e.status === 401) {
          if (e.code === '40106') {
            pushPath = '/rate_limit'
          } else {
            pushPath = '/invalid_session'
          }
        } else if (e.status === 404) {
          if (e.code === '40403') {
            pushPath = '/invalid_url'
          } // 有効なcampaignKeyではない場合
          if (e.code === '40402') {
            pushPath = '/campaign_period'
          } // キャンペーン期間外の場合
        } else {
          // それ以外のエラーの場合
          pushPath = '/app_error'
        }
      } else {
        pushPath = '/app_error'
      }
    } finally {
      this.dispatch(fetchRequestFinish())
      this.push(pushPath, pushJson)
    }
  }
  public async asyncTwitterAuthLottery(
    campaignKey: string | undefined
  ): Promise<void> {
    if (campaignKey === undefined) {
      return
    }
    this.dispatch(fetchRequestAuthLotteryStart())
    try {
      const myHeaders = new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      })
      const requestHash: { webCampaignKey: string } = {
        webCampaignKey: campaignKey
      }
      const body: string = JSON.stringify(requestHash)
      const response: Response = await fetch('/sbg-webcp-twitter-lottery', {
        method: 'POST',
        credentials: 'include',
        headers: myHeaders,
        body: body
      })
      if (response.status === 201 || response.status === 200) {
        // 抽選成功の場合
        const json = await response.json()
        //locaiton.stateに抽選結果の値を渡す
        this.push(`/${campaignKey}/twitter_auth/lottery`, json)
      } else {
        // 抽選失敗の場合
        const json: {
          status: number
          code: string
          message: string
        } = await response.json()
        throw new ApiCallFailError(response.status, json.code)
      }
    } catch (e) {
      bugsnagClient.notify(e)
      if (e.status === 400) {
        // トークンの認証でエラー
        this.push('/invalid_session')
      } else if (e.status === 401) {
        if (e.code === '40102') {
          this.push(`/${campaignKey}?error=${e.code}`, {
            error: '対象アカウントをフォローしてください'
          })
        }
        if (e.code === '40103') {
          this.push(`/${campaignKey}?error=${e.code}`, {
            error: '対象ツイートをリツイートしてください'
          })
        }
        if (e.code === '40105' || e.code === '40106') {
          this.push(`/${campaignKey}?error=${e.code}`, {
            error:
              'アクセスが集中しています。お時間を置いてからお試しください。'
          })
        }
        if (e.code === '40104') {
          this.push(`/${campaignKey}?error=${e.code}`, {
            error:
              'セッションエラーが発生しました。再度最初からお試しください。'
          })
        }
      } else if (e.status === 404) {
        if (e.code === '40403') {
          this.push('/invalid_url')
        } // 有効なcampaignKeyではない場合
        if (e.code === '40402') {
          this.push('/campaign_period')
        } // キャンペーン期間外の場合
      } else {
        // それ以外のエラーの場合
        this.push('/app_error')
      }
    } finally {
      this.dispatch(fetchRequestAuthLotteryFinish())
    }
  }
  public async asyncLineAuthDailyLottery(params: LineParams): Promise<void> {
    if (params.campaignKey === undefined) {
      return
    }
    this.dispatch(fetchRequestStart())
    let pushPath = ''
    let pushJson = undefined
    try {
      const myHeaders = new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${params.idToken}`,
        Accept: 'application/json'
      })
      const requestHash = {
        webCampaignKey: params.campaignKey,
        accessToken: params.accessToken
      }
      const body: string = JSON.stringify(requestHash)
      const response: Response = await fetch(
        `/sbg-webcp-daily-lottery/${params.campaignKey}/daily-lottery`,
        {
          method: 'POST',
          credentials: 'include',
          headers: myHeaders,
          body: body
        }
      )
      if (response.status === 201 || response.status === 200) {
        // 抽選成功の場合
        pushJson = await response.json()
        //locaiton.stateに抽選結果の値を渡す
        pushPath = `/${params.campaignKey}/line_auth/lottery`
      } else {
        // 抽選失敗の場合
        const json: {
          status: number
          code: string
          message: string
        } = await response.json()
        throw new ApiCallFailError(response.status, json.code)
      }
    } catch (e) {
      bugsnagClient.notify(e)
      if (e instanceof ApiCallFailError) {
        if (e.status === 400) {
          // トークンの認証でエラー
          pushPath = '/invalid_session'
        } else if (e.status === 401) {
          if (e.code === '40106') {
            pushPath = '/rate_limit'
          } else {
            pushPath = '/invalid_session'
          }
        } else if (e.status === 404) {
          if (e.code === '40403') {
            pushPath = '/invalid_url'
          } // 有効なcampaignKeyではない場合
          if (e.code === '40402') {
            pushPath = '/campaign_period'
          } // キャンペーン期間外の場合
        } else {
          // それ以外のエラーの場合
          pushPath = '/app_error'
        }
      } else {
        pushPath = '/app_error'
      }
    } finally {
      this.dispatch(fetchRequestFinish())
      this.push(pushPath, pushJson?.result ?? null)
    }
  }
}
