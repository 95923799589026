import 'react-app-polyfill/ie9'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { store, persistor, history } from './store'
import { PersistGate } from 'redux-persist/integration/react'
import { Routes } from './components/router'
import 'bootstrap/dist/css/bootstrap.css'
import './asset/css/style.css'
import { ConnectedRouter } from 'connected-react-router'
import { bugsnagClient } from './bugsnag'
import bugsnagReact from '@bugsnag/plugin-react'
import FallbackComponent from './components/pages/fallBack'

bugsnagClient.use(bugsnagReact, React)
const ErrorBoundary = bugsnagClient.getPlugin('react')

ReactDOM.render(
  <ErrorBoundary FallbackComponent={FallbackComponent}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <Routes />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root') as HTMLElement
)
