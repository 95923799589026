import React from 'react'
import ActionDispatcher from 'src/containers/actionDispatcher'
import PageLoadingIcon from 'src/components/ui/pageLoadingIcon'
import { CampaignState } from 'src/modules/campaign'
import liff from '@line/liff'
import { useEffect } from 'react'
import { bugsnagClient } from 'src/bugsnag'
import ReCAPTCHA from 'react-google-recaptcha'
import Validation from 'src/validate'

interface Props {
  actions: ActionDispatcher
  campaign: CampaignState
  campaignKey: string
  campaignCode: string
  tokenLine: string
}

const WebCasPage: React.FC<Props> = (props: Props): React.ReactElement => {
  const recaptchaRef = React.useRef<ReCAPTCHA | null>(null)

  const { campaign, campaignKey, campaignCode, tokenLine, actions } = props

  /* eslint-disable @typescript-eslint/explicit-function-return-type */
  useEffect(() => {
    const asyncLineAuthLottery = async (): Promise<void> => {
      if (
        !window.document.querySelector(
          'script[src^="https://static.line-scdn.net"]'
        )
      ) {
        const liffId = props.campaign.campaignInfo?.liffId
        await liff
          .init({
            liffId: `${liffId ? liffId : process.env.REACT_APP_LIFF_ID}`,
            mock: false
          })
          .catch((e): void => {
            alert(`LIFF error: ${e.message}`)
            bugsnagClient.notify(e)
          })
      }
      if (window.liff) {
        const liff = window.liff
        const decodedIDToken = liff.getDecodedIDToken()
        if (
          !decodedIDToken ||
          !decodedIDToken.exp ||
          new Date().getTime() > decodedIDToken.exp * 1000
        ) {
          // id tokenの有効期限が切れている場合はliffからlogoutする
          liff.logout()
          return await actions.push(`/liff_app/${campaignKey}`)
        }
        if (props.campaign.campaignInfo?.lineFriendship) {
          // 友だち追加必須であれば判定
          await liff
            .getFriendship()
            .then(
              async (data): Promise<void> => {
                if (!data.friendFlag) {
                  // 友だち追加されていなければエラーテキスト表示
                  actions.push(`/liff_app/${campaignKey}`)
                  return
                } else {
                  if (campaign?.campaignInfo?.isDailyLottery) {
                    await actions.asyncLineAuthDailyLottery({
                      campaignKey: campaignKey,
                      idToken: liff.getIDToken(),
                      accessToken: liff.getAccessToken()
                    })
                  } else {
                    await actions.asyncLineAuthLottery({
                      campaignKey: campaignKey,
                      idToken: liff.getIDToken(),
                      accessToken: liff.getAccessToken()
                    })
                  }
                }
              }
            )
            .catch((e): void => {
              alert(`LIFF Friendship error: ${e.message}`)
              bugsnagClient.notify(e)
            })
        } else {
          if (campaign?.campaignInfo?.isDailyLottery) {
            await actions.asyncLineAuthDailyLottery({
              campaignKey: campaignKey,
              idToken: liff.getIDToken(),
              accessToken: liff.getAccessToken()
            })
          } else {
            await actions.asyncLineAuthLottery({
              campaignKey: campaignKey,
              idToken: liff.getIDToken(),
              accessToken: liff.getAccessToken()
            })
          }
        }
      } else {
        await actions.push(`/liff_app/${campaignKey}`)
        return
      }
    }
    const fetchWebCasSurveyStatus = async (): Promise<void> => {
      try {
        const currentCampaignCode =
          campaign.campaignInfo?.lotteryAuthMethod === 'LINE'
            ? tokenLine
            : campaignCode
        if (currentCampaignCode !== '' && campaignKey !== '') {
          const status = await actions.getWebCasSurveyStatus(
            campaignKey,
            currentCampaignCode
          )
          // update web-campaign status = true
          if (!status) {
            await actions.updateWebCasSurveyStatus(
              campaignKey,
              currentCampaignCode
            )
          }
          if (campaign.campaignInfo?.lotteryAuthMethod === 'LINE') {
            await asyncLineAuthLottery()
            return
          } else if (
            campaign.campaignInfo?.lotteryAuthMethod === 'CampaignCodeForm' ||
            campaign.campaignInfo?.lotteryAuthMethod === 'CampaignCodeUrl'
          ) {
            if (recaptchaRef.current) {
              recaptchaRef.current.execute()
              return
            } else {
              actions.failCampaignCodeInput()
              await actions.push(`/${campaignKey}`)
              return
            }
          }
        }

        if (campaign.campaignInfo?.lotteryAuthMethod === 'LINE') {
          await actions.push(`/liff_app/${campaignKey}`)
          return
        } else {
          actions.failCampaignCodeInput()
          await actions.push(`/${campaignKey}`)
          return
        }
      } catch (error) {
        bugsnagClient.notify(error)
        await actions.push(`/${campaignKey}`)
      }
    }
    fetchWebCasSurveyStatus()
  }, [])

  const activatedRecapture = async (): Promise<void> => {
    return await actions.asyncLottery(campaignCode, campaignKey)
  }

  // recaptureのレンダリング
  const renderRecapture = (): React.ReactElement | undefined => {
    if (campaign.campaignInfo?.webCampaignKey === '') {
      return undefined
    }
    if (
      (campaign.campaignInfo?.lotteryAuthMethod === 'CampaignCodeForm' ||
        (campaign.campaignInfo?.lotteryAuthMethod &&
          campaign.campaignInfo?.lotteryAuthMethod === 'CampaignCodeUrl')) &&
      !Validation.CampaignEndIsAfter(campaign.campaignInfo?.archiveAt)
    ) {
      return (
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={`${process.env.REACT_APP_RECAPTURE_SITE_KEY}`}
          onChange={activatedRecapture} // execute()が終了した場合callback関数として抽選処理が呼ばれる
        />
      )
    }
  }
  return (
    <>
      {renderRecapture()}
      <PageLoadingIcon />;
    </>
  )
}

export default WebCasPage
