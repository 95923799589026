// ActionCreator
import { Action } from 'redux'

enum ActionNames {
  FETCH_START = 'toppage/fetch_request_start',
  FETCH_FINISH = 'toppage/fetch_request_finish',
  FETCH_GET_CAMPAIGN_START = 'toppage/fetch_get_campaign_info_request_start',
  FETCH_GET_CAMPAIGN_FINISH = 'toppage/fetch_get_campaign_info_request_finish',
  FAIL_CAMPAIGN_CODE_INPUT = 'toppage/fail_campaign_code_input'
}

interface FetchRequestStartAction extends Action {
  type: ActionNames.FETCH_START
  appAction: boolean
}

export const fetchRequestStart = (): FetchRequestStartAction => ({
  type: ActionNames.FETCH_START,
  appAction: true
})

interface FetchRequestFinishAction extends Action {
  type: ActionNames.FETCH_FINISH
  appAction: boolean
}
export const fetchRequestFinish = (): FetchRequestFinishAction => ({
  type: ActionNames.FETCH_FINISH,
  appAction: true
})

interface FetchRequestGetCampaignStartAction extends Action {
  type: ActionNames.FETCH_GET_CAMPAIGN_START
  appAction: boolean
}
export const fetchRequestGetCampaignStart = (): FetchRequestGetCampaignStartAction => ({
  type: ActionNames.FETCH_GET_CAMPAIGN_START,
  appAction: true
})

interface FetchRequestGetCampaignFinishAction extends Action {
  type: ActionNames.FETCH_GET_CAMPAIGN_FINISH
  appAction: boolean
}
export const fetchRequestGetCampaignFinish = (): FetchRequestGetCampaignFinishAction => ({
  type: ActionNames.FETCH_GET_CAMPAIGN_FINISH,
  appAction: true
})

interface FailCampaignCodeInputAction extends Action {
  type: ActionNames.FAIL_CAMPAIGN_CODE_INPUT
  appAction: boolean
}
export const failCampaignCodeInput = (): FailCampaignCodeInputAction => ({
  type: ActionNames.FAIL_CAMPAIGN_CODE_INPUT,
  appAction: true
})

//reducer

export interface TopPageState {
  loadingCount: number
  loadingGetCampaignInfoCount: number
  campaignCodeError: boolean
}

export type TopPageActions =
  | FetchRequestStartAction
  | FetchRequestFinishAction
  | FetchRequestGetCampaignStartAction
  | FetchRequestGetCampaignFinishAction
  | FailCampaignCodeInputAction

const initialState: TopPageState = {
  loadingCount: 0,
  loadingGetCampaignInfoCount: 1,
  campaignCodeError: false
}

export default function reducer(
  state: TopPageState = initialState,
  action: TopPageActions
): TopPageState {
  switch (action.type) {
    case ActionNames.FETCH_START: {
      return Object.assign({}, state, { loadingCount: 1 })
    }
    case ActionNames.FETCH_FINISH: {
      return Object.assign({}, state, { loadingCount: 0 })
    }
    case ActionNames.FETCH_GET_CAMPAIGN_START: {
      return Object.assign({}, state, { loadingGetCampaignInfoCount: 1 })
    }
    case ActionNames.FETCH_GET_CAMPAIGN_FINISH: {
      return Object.assign({}, state, { loadingGetCampaignInfoCount: 0 })
    }
    case ActionNames.FAIL_CAMPAIGN_CODE_INPUT: {
      return Object.assign({}, state, { campaignCodeError: true })
    }
    default:
      return state
  }
}
