import toppage, { TopPageActions, TopPageState } from './modules/toppage'
import lottery, { LotteryActions, LotteryState } from './modules/lottery'
import campaign, { CampaignActions, CampaignState } from './modules/campaign'
import { persistStore, persistReducer, createTransform } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createStore, combineReducers, Action, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import { createBrowserHistory } from 'history'
import {
  RouterState,
  routerMiddleware,
  connectRouter,
  RouterAction
} from 'connected-react-router'
import snsAuth, { SnsAuthActions, SnsAuthState } from './modules/snsAuth'
export const history = createBrowserHistory()
const middlewares = [routerMiddleware(history)]
if (process.env.REACT_APP_NODE_ENV !== `production`) {
  const logger = createLogger({
    diff: true,
    collapsed: true
  })
  middlewares.push(logger)
}

const rootReducer = combineReducers({
  router: connectRouter(history),
  toppage,
  lottery,
  campaign,
  snsAuth
})

type CustomState = {
  _persistedAt?: number
}

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const expireTransform = createTransform<
  CustomState,
  CustomState | { _persistedAt?: number }
>(
  inboundState => {
    return {
      ...inboundState,
      _persistedAt: Date.now()
    }
  },
  outboundState => {
    const expireDuration = 1000 * 60 * 60 * 60
    if (Date.now() - (outboundState._persistedAt || 0) > expireDuration) {
      return {}
    }
    return outboundState
  }
)

const persistConfig = {
  key: 'root',
  storage,
  transforms: [expireTransform],
  whitelist: ['lottery']
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

// 永続化設定されたReducerとして定義
const store = createStore(persistedReducer, applyMiddleware(...middlewares))

const persistor = persistStore(store)

export { store, persistor }

//export const persistor = persistStore(store);

export default store

export type ReduxState = {
  router: RouterState
  toppage: TopPageState
  lottery: LotteryState
  campaign: CampaignState
  snsAuth: SnsAuthState
}

export type ReduxAction =
  | TopPageActions
  | LotteryActions
  | CampaignActions
  | SnsAuthActions
  | Action
  | RouterAction
