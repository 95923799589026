import WebCasPage from 'src/components/pages/WebCasPage'
import {
  connect,
  MapDispatchToPropsParam,
  MapStateToPropsParam
} from 'react-redux'
import { ReduxAction, ReduxState } from 'src/store'
import { Dispatch } from 'redux'
import ActionDispatcher from './actionDispatcher'
import { RouteComponentProps } from 'react-router'
import { CampaignState } from 'src/modules/campaign'

type RouterProps = RouteComponentProps<{
  campaignKey: string | undefined
  campaignCode: string | undefined
}>

type StateProps = {
  campaign: CampaignState
  tokenLine: string | undefined
  campaignKey: string | undefined
  campaignCode: string | undefined
}

const mapStateToProps: MapStateToPropsParam<{}, RouterProps, ReduxState> = (
  state: ReduxState,
  ownProps: RouterProps
): StateProps => {
  return {
    campaign: state.campaign,
    tokenLine: state.lottery.campaignCode,
    campaignCode: ownProps.match.params.campaignCode,
    campaignKey: ownProps.match.params.campaignKey
  }
}

const mapDispatchToProps: MapDispatchToPropsParam<
  { actions: ActionDispatcher },
  {}
> = (dispatch: Dispatch<ReduxAction>): { actions: ActionDispatcher } => ({
  actions: new ActionDispatcher(dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(WebCasPage)
