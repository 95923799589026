// ActionCreator
import { Action } from 'redux'

enum ActionNames {
  SET_LOTTERY_RESULT = 'lottery/set_lottery_result',
  SET_CAMPAIGN_CODE = 'lottery/set_campaign_code',
  SET_CAMPAIGN_KEY = 'lottery/set_campaign_key'
}

interface SetLotteryResultAction extends Action {
  type: ActionNames.SET_LOTTERY_RESULT
  lotteryResult: string
}

export const SetLotteryResult = (
  lotteryResult: string
): SetLotteryResultAction => ({
  type: ActionNames.SET_LOTTERY_RESULT,
  lotteryResult: lotteryResult
})

interface SetCampaignCodeStateAction extends Action {
  type: ActionNames.SET_CAMPAIGN_CODE
  campaignCode: string
}

export const SetCampaignCodeState = (
  campaignCode: string
): SetCampaignCodeStateAction => ({
  type: ActionNames.SET_CAMPAIGN_CODE,
  campaignCode: campaignCode
})

interface SetCampaignKeyStateAction extends Action {
  type: ActionNames.SET_CAMPAIGN_KEY
  campaignKey: string
}

export const SetCampaignKeyState = (
  campaignKey: string
): SetCampaignKeyStateAction => ({
  type: ActionNames.SET_CAMPAIGN_KEY,
  campaignKey: campaignKey
})

//reducer

export interface LotteryState {
  campaignKey?: string
  campaignCode?: string
  lotteryResult:
    | {
        giftContent?: string
        productName?: string
        productImageUrl?: string
        notice?: string
        result: string
        wonPageTitle?: string
        wonPageSubTitle?: string
        lostPageTitle?: string
        lostPageText?: string
      }
    | undefined
}

export type LotteryActions =
  | SetLotteryResultAction
  | SetCampaignCodeStateAction
  | SetCampaignKeyStateAction

const initialState: LotteryState = {
  lotteryResult: undefined,
  campaignKey: undefined,
  campaignCode: undefined
}

export default function reducer(
  state: LotteryState = initialState,
  action: LotteryActions
): LotteryState {
  switch (action.type) {
    case ActionNames.SET_LOTTERY_RESULT: {
      return Object.assign({}, state, { lotteryResult: action.lotteryResult })
    }
    case ActionNames.SET_CAMPAIGN_CODE: {
      return Object.assign({}, state, { campaignCode: action.campaignCode })
    }
    case ActionNames.SET_CAMPAIGN_KEY: {
      return Object.assign({}, state, { campaignKey: action.campaignKey })
    }
    default:
      return state
  }
}
