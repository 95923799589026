import React, { useState } from 'react'

interface LotteryHistoryProps {
  lotteryHistory: {
    status: string
    date: string
    giftUrl: string
    giftName: string
  }[]
}
const LotteryHistory: React.FC<LotteryHistoryProps> = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: LotteryHistoryProps
): React.ReactElement | null => {
  const [visibleItems, setVisibleItems] = useState(3)

  const { lotteryHistory } = props
  const loadMore = (): void => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    setVisibleItems(prev => Math.min(prev + 3, lotteryHistory.length))
  }
  return (
    <div className="container" style={{ marginTop: '20px' }}>
      <div
        className="text-center hidden-xs"
        style={{
          fontSize: '24px',
          fontWeight: 'bold',
          marginBottom: '8px',
          color: '#4b6584'
        }}
      >
        抽選履歴
      </div>
      <div
        className="text-center visible-xs"
        style={{
          fontSize: '18px',
          fontWeight: 'bold',
          marginBottom: '8px',
          color: '#4b6584'
        }}
      >
        抽選履歴
      </div>
      <div className="hidden-xs">
        <div
          className="row"
          style={{
            fontWeight: 'bold',
            textAlign: 'center',
            borderBottom: '1px solid #ddd',
            marginLeft: '1px',
            marginRight: '1px'
          }}
        >
          <div
            className="col-sm-6"
            style={{
              padding: '10px'
            }}
          >
            抽選日
          </div>
          <div className="col-sm-6" style={{ padding: '10px' }}>
            抽選結果
          </div>
        </div>

        {// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        lotteryHistory.length ? (
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          lotteryHistory?.slice(0, visibleItems).map((item, index) => (
            <div
              className="row"
              style={{
                borderBottom: '1px solid #ddd',
                textAlign: 'center',
                marginLeft: '1px',
                marginRight: '1px'
              }}
              key={index}
            >
              <div
                className="col-sm-6"
                style={{
                  padding: '10px 0'
                }}
              >
                {item.date}
              </div>
              <div className="col-sm-6" style={{ padding: '10px 0' }}>
                {item.giftName === '' ? (
                  'はずれ'
                ) : item.giftUrl !== '' ? (
                  <a
                    href={item.giftUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    あたり (ギフトはこちら)
                  </a>
                ) : (
                  'あたり'
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="row" style={{ padding: '10px 0' }}>
            まだ抽選履歴はありません{' '}
          </div>
        )}
      </div>
      <div className="visible-xs">
        {// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        lotteryHistory.length ? (
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          lotteryHistory?.slice(0, visibleItems).map((item, index) => (
            <div className="panel panel-default" key={index}>
              <div className="panel-body text-left">
                <p>
                  <strong>抽選日:</strong>
                  {item.date}
                </p>
                <p>
                  <strong>抽選結果:</strong>{' '}
                  {item.giftName === '' ? (
                    'はずれ'
                  ) : item.giftUrl !== '' ? (
                    <a
                      href={item.giftUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      あたり (ギフトはこちら)
                    </a>
                  ) : (
                    'あたり'
                  )}
                </p>
              </div>
            </div>
          ))
        ) : (
          <div className="row">まだ抽選履歴はありません </div>
        )}
      </div>
      {visibleItems < lotteryHistory.length && (
        <div className="text-center" style={{ marginTop: '15px' }}>
          <button className="btn btn-default" onClick={loadMore}>
            もっと見る
          </button>
        </div>
      )}
    </div>
  )
}

export default LotteryHistory
