import { LiffApp } from 'src/components/pages/liffApp'
import {
  connect,
  MapDispatchToPropsParam,
  MapStateToPropsParam
} from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { ReduxAction, ReduxState } from 'src/store'
import { Dispatch } from 'redux'
import ActionDispatcher from './actionDispatcher'
import { CampaignState } from 'src/modules/campaign'
import { TopPageState } from 'src/modules/toppage'
import { LotteryState } from 'src/modules/lottery'

type RouterProps = RouteComponentProps<{
  campaignKey: string | undefined
}>

type StateProps = {
  value: TopPageState
  campaign: CampaignState
  lottery: LotteryState
  campaignKey: string | undefined
}

const mapStateToProps: MapStateToPropsParam<{}, RouterProps, ReduxState> = (
  state: ReduxState,
  ownProps: RouterProps
): StateProps => {
  return {
    value: state.toppage,
    campaignKey: ownProps.match.params.campaignKey,
    campaign: state.campaign,
    lottery: state.lottery
  }
}

const mapDispatchToProps: MapDispatchToPropsParam<
  { actions: ActionDispatcher },
  {}
> = (dispatch: Dispatch<ReduxAction>): { actions: ActionDispatcher } => ({
  actions: new ActionDispatcher(dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(LiffApp)
