import React, { RefObject } from 'react'
import { TopPageState } from 'src/modules/toppage'
import { CampaignState } from 'src/modules/campaign'
import ReCAPTCHA from 'react-google-recaptcha'
import Validation from 'src/validate'
import InvalidUrl from 'src/components/pages/invalidUrl'
import Header from 'src/components/layouts/header'
import PageHeader from 'src/components/layouts/pageHeader'
import Page1Section from 'src/components/layouts/page1Section'
import Page2Section from 'src/components/layouts/page2Section'
import Page3Section from 'src/components/layouts/page3Section'
import Footer from 'src/components/layouts/footer'
import PageLoadingIcon from 'src/components/ui/pageLoadingIcon'
import CampaignTermEnd from 'src/components/layouts/campaignTermEnd'
import LotteryGimmick from 'src/components/layouts/lotteryGimmick'
import { Cookies } from 'react-cookie'
import ActionDispatcher from 'src/containers/actionDispatcher'
import TwitterLotteryButton from 'src/components/ui/twitterLotteryButton'
import HeaderErrorText from 'src/components/ui/headerErrorText'
import CampaignBefore from 'src/components/layouts/campaignBefore'
import CampaignCodeErrorText from 'src/components/ui/campaignCodeErrorText'
import { Redirect } from 'react-router'

interface Props {
  value: TopPageState
  campaign: CampaignState
  actions: ActionDispatcher
  campaignCode?: string
  campaignKey: string
  location?: RouterLocationState
}

interface LocalState {
  campaignCodeInputValue?: string
  locationPathInCookie?: string
}

interface ButtonText {
  [key: string]: string
}

interface ButtonTextHash {
  [key: string]: ButtonText
}

interface RouterLocationState {
  state?: { [key: string]: string }
}
/*
 * 抽選画面Topページでレンダリングされるコンポーネントです。
 * URLパスパラメータcampaignKeyでAPIをコールし、その結果をもとに抽選画面をレンダリングします。
 */
export class TopPage extends React.Component<Props, LocalState> {
  private recaptchaRef: RefObject<ReCAPTCHA>
  constructor(props: Props) {
    super(props)
    this.recaptchaRef = React.createRef<ReCAPTCHA>()
    const cookies = new Cookies()
    this.state = {
      campaignCodeInputValue: '',
      locationPathInCookie: cookies.get(window.location.pathname)
    }
  }

  async componentDidMount(): Promise<void> {
    // ページ読み込み時にキャンペーン情報を取得
    await this.props.actions.asyncGetCampaign(this.props.campaignKey)
  }

  render(): React.ReactElement {
    const { campaignInfo } = this.props.campaign
    // キャンペーン情報を取得できなかった場合
    if (!campaignInfo) {
      return <InvalidUrl />
    }
    // それ以外の場合
    return <div>{this.renderLandingComponent()}</div>
  }

  private renderLandingComponent(): React.ReactElement {
    // キャンペーン情報を取得中の場合
    if (this.props.value.loadingGetCampaignInfoCount !== 0) {
      return <PageLoadingIcon />
    }
    const campaignInfo = this.props.campaign.campaignInfo
    if (!campaignInfo) {
      return <InvalidUrl />
    }
    const {
      lotteryAuthMethod,
      campaignTitle,
      campaignLogoImageUrl,
      contactInformation,
      lotteryGimmick
    } = campaignInfo
    const { campaignCode } = this.props
    // LINE認証の場合
    if (lotteryAuthMethod === 'LINE') {
      return (
        <Redirect
          to={{
            pathname: `/liff_app/${this.props.campaignKey}`
          }}
        />
      )
    }
    if (campaignInfo.lotteryGimmick && this.props.value.loadingCount !== 0) {
      return <LotteryGimmick lotteryGimmick={lotteryGimmick} />
    }
    // キャンペーン期間前の場合
    if (Validation.CampaignStartIsBefore(campaignInfo.startAt)) {
      return <CampaignBefore campaignInfo={campaignInfo} />
    }
    // キャンペーンコードURL認証だがキャンペーンコードが不正な値の場合
    if (
      lotteryAuthMethod === 'CampaignCodeUrl' &&
      (!campaignCode || !Validation.isValidHashCodeLength(campaignCode))
    ) {
      return <InvalidUrl />
    }
    // キャンペーンコードURL認証以外だがキャンペーンコードが指定されている場合
    if (lotteryAuthMethod !== 'CampaignCodeUrl' && campaignCode) {
      return <InvalidUrl />
    }
    return (
      <div>
        <PageHeader campaignTitle={campaignTitle} />
        <HeaderErrorText location={this.props.location} />
        {this.renderRecapture()}
        <Header campaignLogoImageUrl={campaignLogoImageUrl} />
        <article className="container">
          <div className="content">
            <Page1Section>{this.renderPage1Contents()}</Page1Section>
          </div>
        </article>
        <article>
          <div className="content">
            <Page2Section
              campaignInfo={campaignInfo}
              webCampaignKey={this.props.campaignKey}
              isValidArchived={Validation.CampaignEndIsAfter(
                campaignInfo.archiveAt
              )}
            />
            <Page3Section campaignInfo={campaignInfo} />
          </div>
        </article>
        <Footer contactInformation={contactInformation} />
      </div>
    )
  }

  // recaptureのレンダリング
  private renderRecapture(): React.ReactElement | undefined {
    if (!this.props.campaign.campaignInfo) {
      return undefined
    }
    const { lotteryAuthMethod, archiveAt } = this.props.campaign.campaignInfo

    if (
      (lotteryAuthMethod === 'CampaignCodeForm' ||
        lotteryAuthMethod === 'CampaignCodeUrl') &&
      !Validation.CampaignEndIsAfter(archiveAt)
    ) {
      return (
        <ReCAPTCHA
          ref={this.recaptchaRef}
          size="invisible"
          sitekey={`${process.env.REACT_APP_RECAPTURE_SITE_KEY}`}
          onChange={(): Promise<void> => this.activatedRecapture()} // execute()が終了した場合callback関数として抽選処理が呼ばれる
        />
      )
    }
  }

  // #page1エリアレンダリング
  private renderPage1Contents(): React.ReactElement | undefined {
    if (!this.props.campaign.campaignInfo) {
      return undefined
    }
    const buttonTextHash: ButtonTextHash = {
      duringTerm: {
        loadingText: '抽選中',
        lotteryText: '応募する'
      },
      afterTerm: {
        loadingText: '結果確認中',
        lotteryText: '結果を確認する'
      }
    }
    const {
      endAt,
      campaignBannerImageUrl,
      archiveAt
    } = this.props.campaign.campaignInfo
    if (Validation.CampaignEndIsAfter(endAt) === true) {
      //当選確認期間が過ぎている場合
      if (Validation.CampaignEndIsAfter(archiveAt)) {
        return <CampaignTermEnd />
      }
      // キャンペーン期間後
      return (
        <CampaignTermEnd>
          <div className="button-wrapper">
            {this.renderLotteryArea(buttonTextHash.afterTerm)}
          </div>
        </CampaignTermEnd>
      )
    }
    if (this.state.locationPathInCookie) {
      return (
        <div>
          <img src={campaignBannerImageUrl} alt="top_banner" />
          <div className="button-wrapper">
            {this.renderLotteryArea(buttonTextHash.afterTerm)}
          </div>
        </div>
      )
    }
    if (Validation.CampaignEndIsAfter(endAt) === false) {
      // キャンペーン期間内
      return (
        <div>
          <img src={campaignBannerImageUrl} alt="top_banner" />
          <div className="button-wrapper">
            {this.renderLotteryArea(buttonTextHash.duringTerm)}
          </div>
        </div>
      )
    }
    return <div></div>
  }

  // キャンペーンコード入力フォームレンダリング
  private renderCampaignCodeInput(): React.ReactElement | undefined {
    if (
      this.props.campaign.campaignInfo &&
      this.props.campaign.campaignInfo.lotteryAuthMethod === 'CampaignCodeForm'
    ) {
      return (
        <div className="campaign-code-form">
          <h3>キャンペーンコードの入力はこちら</h3>
          <p>キャンペーンコードを半角英数字で入力後ボタンを押してください。</p>
          <input
            type="text"
            value={this.props.campaignCode}
            onChange={this.changeCampaignCodeForm}
            placeholder="キャンペーンコードを入力"
          />
        </div>
      )
    }
  }

  // Recapture成功時の処理
  private async activatedRecapture(): Promise<void> {
    const { campaignKey, campaignCode, actions, campaign } = this.props
    // キャンペーンコード入力認証の場合
    if (
      campaign.campaignInfo &&
      campaign.campaignInfo.lotteryAuthMethod === 'CampaignCodeForm'
    ) {
      return await actions.asyncLottery(
        this.state.campaignCodeInputValue,
        campaignKey
      )
    }
    return await actions.asyncLottery(campaignCode, campaignKey)
  }

  // キャンペーンコードフォーム変更時の処理
  private changeCampaignCodeForm = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    this.setState({ campaignCodeInputValue: event.currentTarget.value })
  }

  // 抽選エリアレンダリング
  private renderLotteryArea(buttonText: ButtonText): React.ReactElement {
    // Twitter認証の場合
    if (
      this.props.campaign.campaignInfo &&
      this.props.campaign.campaignInfo.lotteryAuthMethod === 'Twitter'
    ) {
      return (
        <div>
          <TwitterLotteryButton
            webCampaignKey={this.props.campaignKey}
            buttonTextLottery={buttonText.lotteryText}
          />
        </div>
      )
    }
    return (
      <div>
        {this.renderCampaignCodeInput()}
        <CampaignCodeErrorText
          campaignCodeError={this.props.value.campaignCodeError}
        />
        {this.renderLotteryButton(buttonText)}
      </div>
    )
  }

  private async getWebCasSurveyStatus(): Promise<void> {
    const { campaignKey, campaignCode, actions, campaign } = this.props
    // キャンペーンコード入力認証の場合
    const currentCampaignCode =
      campaign?.campaignInfo?.lotteryAuthMethod === 'CampaignCodeForm'
        ? this.state.campaignCodeInputValue || ''
        : campaignCode || ''

    if (
      campaign.campaignInfo?.webCampaignKey == '' ||
      campaignKey == '' ||
      currentCampaignCode == '' ||
      !Validation.isValidHashCodeLength(currentCampaignCode)
    ) {
      actions.failCampaignCodeInput()
      return
    }
    const status = await actions.getWebCasSurveyStatus(
      campaignKey,
      currentCampaignCode
    )
    if (status === false) {
      await actions.setCampaignCode(currentCampaignCode)
      setTimeout((): void => {
        window.location.href = `${campaign.campaignInfo?.webcasSurveyFormUrl}?id=${currentCampaignCode}`
      }, 100)
    } else {
      this.recaptchaRef.current && this.recaptchaRef.current.execute()
    }
  }

  // 「抽選する」ボタンレンダリング
  private renderLotteryButton(buttonText: ButtonText): React.ReactElement {
    // ローディング中の場合
    if (this.props.value.loadingCount !== 0) {
      // ローディング用文言のボタンを表示する
      return (
        <span className={'button loading'}>
          <span className="spinner-grow"></span>
          {buttonText.loadingText}
        </span>
      )
    }
    //ローディング中ではない場合
    return (
      // 抽選用文言のボタンを表示する
      // クリック時にrecaptureのexecute()が呼ばれる
      <span
        className="button"
        onClick={(): void => {
          if (
            this.props.campaign?.campaignInfo?.webcasSurveyFormUrl &&
            this.props.campaign?.campaignInfo?.webcasSurveyFormUrl !== ''
          ) {
            this.getWebCasSurveyStatus()
          } else {
            this.recaptchaRef.current?.execute()
          }
        }}
      >
        {buttonText.lotteryText}
      </span>
    )
  }
}
